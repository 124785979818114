.error-message {
  color: red;
}

.login-form {
  flex: 3;
  margin: 0 10px; /* 両側にマージンを設定 */
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-form-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-form-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  box-sizing: border-box; /* パディングとボーダーを幅に含める */
}

.login-button {
  margin-left: auto;
  display: flex;
  align-self: flex-end; /* ボタンを右寄せにする */
}

/* 入力フィールドを線で囲むスタイル */
.login-form-child {
  border: 1px solid #ccc; /* 枠線の色 */
  border-radius: 4px; /* 枠線の角を丸くする */
  padding: 8px; /* 内側の余白 */
  margin-bottom: 16px; /* 下のマージン */
}
