.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 80%;
  text-align: center;
  z-index: 1001;
  overflow-y: auto; /* ここを追加して、縦方向にスクロール可能にします */
}

.buki-type-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.buki-type-name {
  font-family: "Helvetica Neue", sans-serif; /* フォントファミリー */
  color: #333; /* 文字色 */
  background-color: #f8f8f8; /* 背景色 */
  text-transform: uppercase; /* 大文字に変換 */
  border-bottom: 2px solid #eaeaea; /* 下線のスタイル */
  padding: 10px; /* 上下のパディング */
  margin: 10px 0; /* 上下のマージン */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 影のスタイル */
  width: 100%;
}

.buki-type-container img {
  border: 3px solid transparent;
  transition: transform 0.3s ease, border-color 0.3s ease;
  width: 100px;
  height: auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.buki-type-container img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.image-gallery .active {
  border-color: #4422ff; /* 選択された画像のボーダーカラー */
}

.image-gallery .grayedOut {
  filter: grayscale(20%); /* 選択されていない画像をグレーアウト */
  opacity: 0.6;
}

/* スマホ用のスタイル */
@media screen and (max-width: 767px) {
  .buki-type-container img {
    width: 50px;
  }
}
